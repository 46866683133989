import {
  GET_BANK_BY_IBAN,
  SET_BANK_LOADING,
  SET_IBAN_HAS_BANK,
} from '../actions/types';

const initialState = {
  loading: false,
  ibanHasBank: false,
  bank: {},
  banks: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_BANK_BY_IBAN: {
      const { name, swift } = action.payload;
      if (name && swift) {
        return {
          ...state,
          ibanHasBank: true,
          bank: {
            bank_name: name,
            swift: swift,
          },
        };
      }
      return {
        ...state,
        ibanHasBank: false,
        bank: {},
      };
    }
    case SET_BANK_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case SET_IBAN_HAS_BANK: {
      return {
        ...state,
        ibanHasBank: action.payload,
      };
    }
    default:
      return state;
  }
}
