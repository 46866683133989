/* eslint-disable no-param-reassign */
import {
  GET_TABLE_CONFIG,
  GET_USER_TABLE_CONFIG,
  SET_MOVED_TABLE_CONFIG,
  SET_CHECKED_TABLE,
  APPLY_TABLE_CHANGES,
  SET_TABLECONFIG_VISIBLE,
  SET_COLUMNG_CONFIGS,
  SET_SELECTED_COLUMN,
  CLEAR_TABLE_CONFIG,
} from '../actions/types';

const initialState = {
  columnConfigs: [],
  selectedConfig: {
    fields: [],
  },
  selectedColumnsForTable: [],
  tableConfig: {
    displayTableConfig: false,
    tableUserConfigData: [],
    tableConfigData: [],
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CLEAR_TABLE_CONFIG: {
      return initialState;
    }
    case SET_SELECTED_COLUMN: {
      let newSelectedColumnsForTable = [];
      const allPossibleFields = [...action.payload.fields];
      allPossibleFields.forEach(field => {
        const selectedItem = state.tableConfig.tableConfigData.find(
          selectedField => selectedField.field === field,
        );
        if (selectedItem) {
          newSelectedColumnsForTable = [
            ...newSelectedColumnsForTable,
            { ...selectedItem, show: true },
          ];
        }
      });
      return {
        ...state,
        selectedConfig: action.payload,
        tableConfig: {
          ...state.tableConfig,
          tableUserConfigData: newSelectedColumnsForTable,
        },
      };
    }
    case GET_TABLE_CONFIG: {
      let newSelectedColumnsForTable = [];
      const allPossibleFields = [...state.selectedConfig.fields];
      allPossibleFields.forEach(field => {
        const selectedItem = action.payload.find(
          selectedField => selectedField.field === field,
        );
        if (selectedItem) {
          newSelectedColumnsForTable = [
            ...newSelectedColumnsForTable,
            { ...selectedItem, show: true },
          ];
        }
      });
      const tableUserConfigOptions = [...state.tableConfig.tableUserConfigData];
      const tableConfigurationOptions = [...action.payload];
      let newTableConfigsSorted = [];
      let newUserTableConfigs = [];
      tableUserConfigOptions.forEach(userTableOption => {
        const singleTableConfig = tableConfigurationOptions.findIndex(
          tableOption => tableOption.field === userTableOption,
        );
        if (singleTableConfig !== -1) {
          tableConfigurationOptions[singleTableConfig].show = true;
          newTableConfigsSorted = [
            ...newTableConfigsSorted,
            tableConfigurationOptions[singleTableConfig],
          ];
          newUserTableConfigs = [
            ...newUserTableConfigs,
            tableConfigurationOptions[singleTableConfig],
          ];
          tableConfigurationOptions.splice(singleTableConfig, 1);
        }
      });
      return {
        ...state,
        selectedColumnsForTable: newSelectedColumnsForTable,
        tableConfig: {
          ...state.tableConfig,
          tableConfigData: [
            ...newUserTableConfigs,
            ...tableConfigurationOptions,
          ],
          tableUserConfigData: newSelectedColumnsForTable,
        },
      };
    }
    case GET_USER_TABLE_CONFIG: {
      const selectedConfig = action.payload.find(config => config.default);
      if (selectedConfig) {
        return {
          ...state,
          selectedConfig,
          tableConfig: {
            ...state.tableConfig,
            // tableUserConfigData: action.payload,
          },
        };
      }
      return { ...state };
    }
    case SET_COLUMNG_CONFIGS: {
      return {
        ...state,
        columnConfigs: action.payload,
      };
    }
    case SET_MOVED_TABLE_CONFIG: {
      return {
        ...state,
        tableConfig: {
          ...state.tableConfig,
          tableConfigData: action.payload,
        },
      };
    }
    case SET_CHECKED_TABLE: {
      const arrayOfConfigOptions = [...state.tableConfig.tableConfigData];
      arrayOfConfigOptions[action.payload.index] = {
        ...arrayOfConfigOptions[action.payload.index],
        show: action.payload.isChecked,
      };
      return {
        ...state,
        tableConfig: {
          ...state.tableConfig,
          tableConfigData: arrayOfConfigOptions,
        },
      };
    }
    case APPLY_TABLE_CHANGES: {
      const userTableConfig = state.tableConfig.tableConfigData.filter(
        config => config.show,
      );
      const sortedtableConfigs = state.tableConfig.tableConfigData.sort(
        (a, b) => b.show - a.show,
      );
      return {
        ...state,
        tableConfig: {
          ...state.tableConfig,
          tableUserConfigData: userTableConfig,
          tableConfigData: sortedtableConfigs,
          displayTableConfig: false,
        },
      };
    }
    case SET_TABLECONFIG_VISIBLE: {
      return {
        ...state,
        tableConfig: {
          ...state.tableConfig,
          displayTableConfig: !state.tableConfig.displayTableConfig,
        },
      };
    }
    default:
      return state;
  }
}
