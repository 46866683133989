import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';

import setPageTitle from '../actions/navigationActions';

const AddTitleToPage = ({ title, titleWithoutDashboard }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!titleWithoutDashboard) {
      dispatch(setPageTitle(title));
    }
  }, [title, titleWithoutDashboard, dispatch]);

  return (
    <Helmet>
      <title>{titleWithoutDashboard || title}</title>
    </Helmet>
  );
};

AddTitleToPage.propTypes = {
  title: PropTypes.string,
  titleWithoutDashboard: PropTypes.string,
};

AddTitleToPage.defaultProps = {
  title: undefined,
  titleWithoutDashboard: undefined,
};

export default AddTitleToPage;
