import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  FormFeedback,
  Label,
  Spinner,
} from 'reactstrap';

import axios from '../../../axios';

import AddTitleToPage from '../../commonComponents/AddTitleToPage';

function ResetPassword(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isAlertShown, setIsAlertShown] = useState(false);
  const [err, setErr] = useState({});
  const [data, setData] = useState({ email: '' });
  const history = useHistory();

  const resetPassword = async e => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await axios.post('/v1/account/password-reset/', {
        email: data.email,
      });
      if (response.status === 200) {
        setIsLoading(false);
        setIsAlertShown(true);
        setData({ email: '' });
      }
    } catch (error) {
      setIsLoading(false);
      setErr(error.response.data);
    }
  };

  const onChange = e => {
    e.persist();
    setErr({});
    setData(prevData => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };
  return (
    <div className="app flex-row align-items-center">
      <AddTitleToPage title="Passwort Zurücksetzen" />
      <Container>
        <Row className="justify-content-center">
          <Col xs="5">
            <CardGroup>
              <Card className="p-4">
                <CardBody>
                  <Form noValidate onSubmit={resetPassword}>
                    <h1 className="mb-5">Passwort Zurücksetzen</h1>
                    <p className="text-muted" />
                    <Label>Email eingeben</Label>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-user" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="email"
                        value={data.email}
                        placeholder="Email"
                        autoComplete="email"
                        name="email"
                        onChange={onChange}
                        invalid={!!err.email || !!err.non_field_errors}
                      />
                      <FormFeedback>
                        {err.email &&
                          err.email.map(i => <div key={i}>{i}</div>)}
                      </FormFeedback>
                    </InputGroup>
                    <Row>
                      <Col xs="6">
                        <Button
                          color="primary"
                          className="px-4 w-100"
                          onClick={resetPassword}
                        >
                          {isLoading ? (
                            <Spinner color="light" size="sm" />
                          ) : (
                            'Zurücksetzen'
                          )}
                        </Button>
                      </Col>
                      <Col xs="6" className="text-right">
                        <Button
                          onClick={() => history.push('/login')}
                          color="link"
                          className="px-0"
                        >
                          Zurück zur Anmeldung
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                  {isAlertShown && (
                    <div className="mt-3 alert alert-success" role="alert">
                      Wir haben Ihnen eine E-mail zum Zurücksetzten des
                      Passwortes gesendet.
                    </div>
                  )}
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

ResetPassword.propTypes = {
  history: PropTypes.PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.object,
      PropTypes.string,
      PropTypes.number,
      PropTypes.func,
    ]),
  ).isRequired,
};

export default ResetPassword;
