import React, { useEffect, useState } from 'react';
import { Row, Col, Badge, Card } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from '../../../axios';
import KindergartenCard from './KindergartenCard';
import AddTitleToPage from '../../commonComponents/AddTitleToPage';
import Loading from '../../commonComponents/LoadingSpinner';

const Kindergartens = props => {
  const [data, setData] = useState();
  const [responseStatus, setResponseStatus] = useState('');
  const [isUnmount, setIsUnmount] = useState(false);

  const { userInfo, history } = props;
  const { schools, is_company_admin: isAdmin } = userInfo;
  const { permissions } = userInfo || '';

  const canAddSchool = permissions && permissions.includes('school.add_school');
  const loadData = async () => {
    const response = await axios.get('/v1/account/user/schools/');
    setData(response.data);
    setResponseStatus(response.status);
  };

  useEffect(() => {
    // if (schools && schools.length === 1 && !isAdmin) {
    //   history.push(`${schools[0]}/school/`);
    // }

    if (!isUnmount && history.location.pathname === '/') {
      loadData();
    }

    return function cleanup() {
      setIsUnmount(true);
    };
  }, [schools, isAdmin, history, isUnmount]);

  return responseStatus === 200 ? (
    <>
      <AddTitleToPage title="Übersicht der Einrichtungen" />
      <Row className="mt-3 mb-2">
        <Col xs="8">
          <h2>
            Übersicht der Einrichtungen - &nbsp;
            <Badge color="primary">{(data && data.length) || 0}</Badge>
          </h2>
        </Col>
        <Col xs="4">
          {canAddSchool && (
            <NavLink to="/schools/new" className="btn btn-primary float-right">
              <i className="fas fa-plus" />
              &nbsp; Einrichtung hinzufügen
            </NavLink>
          )}
        </Col>
      </Row>
      <Row>
        {data.map(i => {
          return (
            <Col xs="4" key={i.id} className="mb-4">
              <KindergartenCard data={i} />
            </Col>
          );
        })}
        {canAddSchool && (
          <Col xs="4" key="addNew" className="mb-4">
            <NavLink to="/schools/new" className="text-decoration-none">
              <Card className="kindergartenNewCard">
                <div className="py-3 px-2 kindergartenNewCard-icon">
                  <div>
                    <i className="fas fa-plus plus-sign" />
                    &nbsp; Einrichtung hinzufügen
                  </div>
                </div>
              </Card>
            </NavLink>
          </Col>
        )}
      </Row>
    </>
  ) : (
    <Loading />
  );
};

Kindergartens.propTypes = {
  userInfo: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
      PropTypes.object,
      PropTypes.bool,
    ]),
  ).isRequired,
  history: PropTypes.PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.object,
      PropTypes.string,
      PropTypes.number,
      PropTypes.func,
    ]),
  ).isRequired,
};

const mapStateToProps = state => ({
  userInfo: state.auth.user,
});

export default connect(mapStateToProps)(Kindergartens);
