import { INCREASE_COUNTER, DECREASE_COUNTER } from '../actions/types';

const initialState = {
  reqCounter: 0,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case INCREASE_COUNTER: {
      return {
        ...state,
        reqCounter: state.reqCounter + 1,
      };
    }
    case DECREASE_COUNTER: {
      if (state.reqCounter <= 0) {
        return initialState;
      }
      return {
        ...state,
        reqCounter: state.reqCounter - 1,
      };
    }
    default:
      return state;
  }
}
