/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';

const KindergartenCard = props => {
  const { data } = props;
  const {
    id,
    email,
    name,
    city,
    address,
    extra,
    phone_number1,
    postal_code,
    logo,
  } = data;
  const user =
    localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));
  const { permissions } = user || '';
  const canEditSchool =
    permissions && permissions.includes('school.change_school');
  return (
    <>
      <Card className="text-white kindergartenCard">
        <Row className="kindergartenNameHolder">
          <h1>
            {name}
            {/* Naturkindergarten Kleine Rotfüchse in Maulbronn */}
          </h1>
        </Row>
        <Row className="kindergartenContentHolder">
          <Col xs="7" className="kindergartenContentHolder-text">
            <ul>
              <li>
                Ansprechpartner: <br />
                {extra && extra.length > 0 ? extra[0].name : <br />}
              </li>
              <li>
                {address} <br />
                {postal_code} {city}
              </li>
              <li>Tel: {phone_number1}</li>
              <li>
                Email: <br />
                {email}
              </li>
            </ul>
          </Col>
          <Col xs="5" className="kindergartenContentHolder-img">
            {logo ? (
              <img src={logo || ''} alt="logo" className="logo" />
            ) : (
              <div className="alt-logo">
                <i className="fas fa-child" />
              </div>
            )}
          </Col>
        </Row>
        <Row className="kindergartenBtnHolder">
          <Col md="6">
            <NavLink className="btn btn-block btn-light open-btn" to={`/${id}`}>
              Einrichtung öffnen
            </NavLink>
          </Col>
          <Col md="6">
            {canEditSchool && (
              <NavLink
                className="editBtn btn btn-block btn-outline-light edit-btn"
                to={`/${id}/schools/edit`}
              >
                Bearbeiten
                {/* &nbsp; */}
              </NavLink>
            )}
          </Col>
        </Row>
      </Card>
    </>
  );
};
KindergartenCard.propTypes = {
  data: PropTypes.PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
  ).isRequired,
};
export default KindergartenCard;
