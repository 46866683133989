import { combineReducers } from 'redux';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import navigationReducer from './navigationReducer';
import schoolReducer from './schoolReducer';
import tableConfigReducer from './tableConfigReducer';
import bankReducer from './bankReducer';
import overlayReducer from './overlayReducer';

export default combineReducers({
  auth: authReducer,
  err: errorReducer,
  navigation: navigationReducer,
  school: schoolReducer,
  tableConfig: tableConfigReducer,
  banks: bankReducer,
  overlay: overlayReducer,
});
