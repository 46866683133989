import * as Sentry from '@sentry/browser';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import setToken from '../config/axios';
// import { hotjar } from 'react-hotjar';
import store from '../store';
import { refreshToken } from './actions/authActions';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from './commonComponents/LoadingSpinner';
import Overlay from './commonComponents/Overlay/Overlay';
import PrivateRoute from './commonComponents/PrivateRoute';
import {
  clearFiltersFromSessionStorage,
  getFiltersFromSessionStorage,
} from './helpers/filtersToSessionStorage';
import Kindergartens from './reactApp/kindergarten';
import ResetPassword from './reactApp/login/ResetPassword';
import ResetPasswordConfirm from './reactApp/login/ResetPasswordConfirm';

// import helppierInit from '../assets/scripts/helppier';

// if (process.env.REACT_APP_ENV === 'production') {
//   hotjar.initialize(1784025, 6);
// }

// Pages
const CreateCompany = React.lazy(() =>
  import('./reactApp/Company/CreateCompany'),
);
const FamlyLogin = React.lazy(() => import('./reactApp/Famly/Login'));
const Login = React.lazy(() => import('./reactApp/login/Login'));
// const Dashboard = React.lazy(() => import('./reactApp/dashboard/Dashboard'));
const DefaultLayout = React.lazy(() =>
  import('./reactApp/containers/DefaultLayout/DefaultLayout'),
);

function App() {
  const history = useHistory();
  const location = useLocation();
  const reqCounter = useSelector(state => state.overlay.reqCounter);

  useEffect(() => {
    // let targetUrl = location.pathname;
    const targetUrl = location.pathname.split('/');
    if (targetUrl[2] !== 'children' && getFiltersFromSessionStorage('childs')) {
      clearFiltersFromSessionStorage('childs');
    } else if (
      targetUrl[2] !== 'employee' &&
      getFiltersFromSessionStorage('employee')
    ) {
      clearFiltersFromSessionStorage('employee');
    } else if (
      targetUrl[2] !== 'cash-accounts' &&
      getFiltersFromSessionStorage('cash-accounts')
    ) {
      clearFiltersFromSessionStorage('cash-accounts');
    } else if (
      targetUrl[1] !== 'bank-accounts' &&
      getFiltersFromSessionStorage('bank-accounts')
    ) {
      clearFiltersFromSessionStorage('bank-accounts');
    }
  }, [location]);

  useEffect(() => {
    if (localStorage.token && localStorage.user) {
      // helppierInit();
      store.dispatch(refreshToken(history));
      setToken(localStorage.token);
    } else if (!localStorage.token || !localStorage.user) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      // Reconfigure centry here
      process.env.REACT_APP_USE_SENTRY === 'true'
    ) {
      const userData =
        JSON.parse(localStorage.getItem('user') || null) || undefined;
      Sentry.init({
        dsn:
          'https://81e6c4e8a9cd43748e548050a6c41574@o1129135.ingest.sentry.io/6172748',
      });
      // if (userData) {
      Sentry.configureScope(scope => {
        scope.setUser(userData);
      });
      Sentry.addBreadcrumb(userData);
      // }
    }
  }, []);

  return (
    <React.Suspense fallback={<LoadingSpinner />}>
      <ToastContainer />
      {reqCounter > 0 && <Overlay />}
      <Switch>
        <Route
          path="/password-reset/:tokenParams"
          component={ResetPasswordConfirm}
        />
        <Route exact path="/password-reset" component={ResetPassword} />
        <Route path="/create-company" component={CreateCompany} />
        <Route path="/login" component={Login} />
        <Route path="/famly-login" component={FamlyLogin} />
        <PrivateRoute
          path="/kindergartens"
          component={props => <Kindergartens routerData={props} />}
        />
        {/* <Route path="/dashboard" component={Dashboard} /> */}
        <PrivateRoute path="/" component={DefaultLayout} />
      </Switch>
    </React.Suspense>
  );
}

export default App;
