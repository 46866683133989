import React from 'react';
import { Spinner } from 'reactstrap';

import './loadingSpinner.css';

const LoadingSpinner = () => (
  <>
    <div className="wrapper">
      <Spinner className="spinner" />
    </div>
  </>
);

export default LoadingSpinner;
