import axios from 'axios';
import * as Sentry from '@sentry/browser';

import { errorNotification } from '../assets/toastify';
import {
  increaseCounter,
  decreaseCounter,
} from '../app/actions/overlayActions';

import store from '../store';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}api`,
});

axiosInstance.interceptors.request.use(
  config => {
    const methodType = config.method;
    if (
      methodType === 'patch' ||
      methodType === 'put' ||
      methodType === 'post' ||
      methodType === 'delete'
    ) {
      store.dispatch(increaseCounter());
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  response => {
    const methodType = response.config.method;
    if (
      methodType === 'patch' ||
      methodType === 'put' ||
      methodType === 'post' ||
      methodType === 'delete'
    ) {
      store.dispatch(decreaseCounter());
    }
    return response;
  },
  error => {
    const methodType = error.config.method;
    if (
      methodType === 'patch' ||
      methodType === 'put' ||
      methodType === 'post' ||
      methodType === 'delete'
    ) {
      store.dispatch(decreaseCounter());
    }
    if (
      error.response.status === 400 &&
      error.response.data.non_field_errors &&
      error.response.data.non_field_errors.length
    ) {
      error.response.data.non_field_errors.forEach(errTxt => {
        errorNotification(errTxt, 6000);
      });
    }
    if (error.response.status === 403) {
      Sentry.captureException(error.response);
      // You don't have permission for this action.
      errorNotification(
        'Für diese Aktion haben Sie keine Berechtigung. Bitte wenden Sie sich an den Administrator.',
      );
    } else if (error.response.status > 499 && error.response.status < 600) {
      // Something went wrong
      errorNotification(
        'Oooops, hier hat etwas nicht geklappt. Bitte versuche es erneut oder wende dich an unseren Support.',
      );
    } else {
      return Promise.reject(error);
    }
  },
);

export default axiosInstance;
