import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  FormFeedback,
  Label,
  Spinner,
} from 'reactstrap';
import queryString from 'query-string';

import axios from '../../../axios';

import AddTitleToPage from '../../commonComponents/AddTitleToPage';
import { useHistory } from 'react-router-dom';

export default function ResetPasswordConfirm({ location }) {
  const [isLoading] = useState(false);
  const [isAlertShown, setIsAlertShown] = useState(false);
  const [err, setErr] = useState({});
  const [data, setData] = useState({ password: '', rePassword: '' });
  const history = useHistory();

  const resetPasswordConfirm = async e => {
    e.preventDefault();
    if (data.password !== data.rePassword) {
      setErr({
        ...err,
        password: ['Sie müssen das gleiche Passwort eingeben'],
      });
      return;
    }
    try {
      const token = queryString.parse(location.search);
      const response = await axios.post('/v1/account/password-reset/confirm/', {
        password: data.password,
        token: token.token,
      });
      if (response.status === 200) {
        setIsAlertShown(true);
        setData({ ...data, email: '' });
      }
    } catch (error) {
      setErr(error.response.data);
    }
  };

  const onChange = e => {
    e.persist();
    setErr({});
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <div className="app flex-row align-items-center">
      <AddTitleToPage title="Passwort zurücksetzen" />
      <Container>
        <Row className="justify-content-center">
          <Col xs="5">
            <CardGroup>
              <Card className="p-4">
                <CardBody>
                  <Form noValidate onSubmit={resetPasswordConfirm}>
                    <h1 className="mb-5">Passwort zurücksetzen</h1>
                    <p className="text-muted" />
                    {!isAlertShown && (
                      <>
                        <Row>
                          <Col>
                            <Label>Neues Passwort eingeben</Label>
                            <InputGroup className="mb-3">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="icon-lock" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Col>
                                <Row
                                  className={`${
                                    err.password ? 'pb-2' : 'pb-4'
                                  }`}
                                >
                                  <Input
                                    className="rounded-0"
                                    type="password"
                                    value={data.password}
                                    placeholder="Neues Passwort eingeben"
                                    autoComplete="password"
                                    name="password"
                                    onChange={onChange}
                                    invalid={
                                      !!err.password || !!err.non_field_errors
                                    }
                                  />
                                  {err.password && (
                                    <label className="text-danger ml-1 mr-1 pt-0">
                                      <small className="p-0">
                                        {err.password[0]}
                                      </small>
                                    </label>
                                  )}
                                </Row>
                                <Row>
                                  <Input
                                    className="rounded-0"
                                    type="password"
                                    value={data.rePassword}
                                    placeholder="Geben Sie erneut ein neues Passwort ein"
                                    autoComplete="password"
                                    name="rePassword"
                                    onChange={onChange}
                                    invalid={
                                      !!err.password || !!err.non_field_errors
                                    }
                                  />
                                </Row>
                              </Col>
                              <FormFeedback>
                                {err.password &&
                                  err.password.map(i => (
                                    <div key={JSON.stringify(i)}>{i}</div>
                                  ))}
                              </FormFeedback>
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="6">
                            <Button
                              color="primary"
                              className="px-4 w-100"
                              onClick={resetPasswordConfirm}
                            >
                              {isLoading ? (
                                <Spinner color="light" size="sm" />
                              ) : (
                                'Bestätigen'
                              )}
                            </Button>
                          </Col>
                          <Col xs="6" className="text-right">
                            <Button
                              onClick={() => history.push('/login')}
                              color="link"
                              className="px-0"
                            >
                              Zurück zur Anmeldung
                            </Button>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Form>
                  {isAlertShown && (
                    <div className="mt-3 alert alert-success" role="alert">
                      Das Passwort wurde erfolgreich geändert.{' '}
                      <Button
                        onClick={() => history.push('/login')}
                        color="link"
                        className="px-0"
                      >
                        Klicken Sie hier, um sich einzuloggen
                      </Button>
                    </div>
                  )}
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

ResetPasswordConfirm.propTypes = {
  history: PropTypes.PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.object,
      PropTypes.string,
      PropTypes.number,
      PropTypes.func,
    ]),
  ).isRequired,
  location: PropTypes.PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.object,
      PropTypes.string,
      PropTypes.number,
      PropTypes.func,
    ]),
  ).isRequired,
};
