import React from 'react';
import Spinner from '../LoadingSpinner';

import './Overlay.css';

const Overlay = () => {
  return (
    <div className="overlay_main">
      <Spinner />
    </div>
  );
};

export default Overlay;
