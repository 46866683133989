export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_ERRORS = 'GET_ERRORS';
export const REMOVE_ERRORS = 'REMOVE_ERRORS';
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const SET_SCHOOL = 'SET_SCHOOL';
export const SET_SCHOOL_MENU_VISIBLE = 'SET_SCHOOL_MENU_VISIBLE';
export const SET_SCHOOL_MENU_INVISIBLE = 'SET_SCHOOL_MENU_INVISIBLE';
export const LOAD_SCHOOL_MENU = 'LOAD_SCHOOL_MENU';
// Table Actions
export const GET_TABLE_CONFIG = 'GET_TABLE_CONFIG';
export const GET_USER_TABLE_CONFIG = 'GET_USER_TABLE_CONFIG';
export const SET_MOVED_TABLE_CONFIG = 'SET_MOVED_TABLE_CONFIG';
export const SET_CHECKED_TABLE = 'SET_CHECKED_TABLE';
export const SET_TABLECONFIG_VISIBLE = 'SET_TABLECONFIG_VISIBLE';
export const APPLY_TABLE_CHANGES = 'APPLY_TABLE_CHANGES';
export const SET_COLUMNG_CONFIGS = 'SET_COLUMNG_CONFIGS';
export const SET_SELECTED_COLUMN = 'SET_SELECTED_COLUMN';
export const CLEAR_TABLE_CONFIG = 'CLEAR_TABLE_CONFIG';
export const DELETE_COLUMN_CONFIG = 'DELETE_COLUMN_CONFIG';

// Bank Actions
export const GET_BANK_BY_IBAN = 'GET_BANK_BY_IBAN';
export const SET_BANK_LOADING = 'SET_BANK_LOADING';
export const SET_IBAN_HAS_BANK = 'SET_IBAN_HAS_BANK';

// Overlay Actions
export const INCREASE_COUNTER = 'INCREASE_COUNTER';
export const DECREASE_COUNTER = 'DECREASE_COUNTER';
