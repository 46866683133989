export function setFiltersToSessionStorage(storageName, storageVal) {
  sessionStorage.setItem(storageName, JSON.stringify(storageVal));
}

export function getFiltersFromSessionStorage(storageName) {
  return JSON.parse(sessionStorage.getItem(storageName));
}

export function clearFiltersFromSessionStorage(storageName) {
  sessionStorage.removeItem(storageName);
}

export function setUrlParamsFromFilter(activeFilters) {
  let date = [];
  Object.keys(activeFilters).forEach(key => {
    if (
      (!Array.isArray(activeFilters[key]) &&
        typeof activeFilters[key] !== 'boolean' &&
        activeFilters[key]) ||
      (!Array.isArray(activeFilters[key]) &&
        typeof activeFilters[key] === 'boolean') ||
      (Array.isArray(activeFilters[key]) && activeFilters[key].length > 0)
    ) {
      date.push(`${key}=${activeFilters[key]}`);
    }
  });

  return (date = '?' + date.join('&'));
}
