import { toast } from 'react-toastify';

export const errorNotification = (text, closeTime = 5000) => {
  return toast.error(text, {
    position: 'top-right',
    autoClose: closeTime,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
  });
};

export const successNotification = (text, closeTime = 5000) => {
  return toast.success(text, {
    position: 'top-right',
    autoClose: closeTime,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
  });
};
