import {
  SET_SCHOOL,
  SET_SCHOOL_MENU_VISIBLE,
  SET_SCHOOL_MENU_INVISIBLE,
  LOAD_SCHOOL_MENU,
} from '../actions/types';

const initialState = {
  school: {},
  displaySchoolMenu: false,
  loadSchoolMenu: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_SCHOOL: {
      return {
        ...state,
        school: action.payload,
      };
    }
    case SET_SCHOOL_MENU_VISIBLE: {
      return {
        ...state,
        displaySchoolMenu: true,
      };
    }
    case SET_SCHOOL_MENU_INVISIBLE: {
      return {
        ...state,
        displaySchoolMenu: false,
      };
    }
    case LOAD_SCHOOL_MENU: {
      return {
        ...state,
        loadSchoolMenu: action.payload,
      };
    }
    default:
      return state;
  }
}
