import axios from '../../axios';
import setAuthToken from '../../config/axios';
import { GET_ERRORS, REMOVE_ERRORS, SET_CURRENT_USER } from './types';

// import helppierInit from '../../assets/scripts/helppier';

export const setCurrentUser = userData => ({
  type: SET_CURRENT_USER,
  payload: userData,
});
export const loginUser = userData => async dispatch => {
  try {
    const user = await axios.post('/v1/account/login/', userData);
    localStorage.setItem('token', user.data.token);
    localStorage.setItem('user', JSON.stringify(user.data.user));
    // helppierInit();
    setAuthToken(user.data.token);
    dispatch(setCurrentUser(user.data.user));
    dispatch({
      type: REMOVE_ERRORS,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};
export const setTokenAndUser = (token, userData) => {
  localStorage.setItem('token', token);
  localStorage.setItem('user', JSON.stringify(userData));
  // helppierInit();
  setAuthToken(token);
};

export const updateUserData = userData => async dispatch => {
  try {
    dispatch(setCurrentUser(userData));
    dispatch({
      type: REMOVE_ERRORS,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const refreshToken = history => async dispatch => {
  try {
    const refreshedToken = await axios.post('/v1/account/verify-jwf-token/', {
      token: localStorage.token,
    });
    localStorage.setItem('token', refreshedToken.data.token);
    localStorage.setItem('user', JSON.stringify(refreshedToken.data.user));
    dispatch(setCurrentUser(refreshedToken.data.user));
  } catch (err) {
    localStorage.clear();
    history.push('/login');
  }
};

export const logoutUser = () => dispatch => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  setAuthToken(false);
  dispatch(setCurrentUser({}));
  dispatch({
    type: REMOVE_ERRORS,
  });
  window.location.reload();
};
