import { INCREASE_COUNTER, DECREASE_COUNTER } from './types';

export const increaseCounter = () => dispatch => {
  dispatch({
    type: INCREASE_COUNTER,
  });
};

export const decreaseCounter = () => dispatch => {
  dispatch({
    type: DECREASE_COUNTER,
  });
};
