import axios from '../axios';

const authorization = 'Authorization';

const setAuthToken = token => {
  if (token) {
    axios.defaults.headers.common[authorization] = `JWT ${token}`;
  } else {
    delete axios.defaults.headers.common[authorization];
  }
};

export default setAuthToken;
