import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const PrivateRoute = props => {
  const { permission } = props;
  const userData = JSON.parse(localStorage.getItem('user'));
  if (localStorage.getItem('token') && userData) {
    if (permission && !userData.permissions.includes(permission)) {
      return <Redirect to="/" />;
    }
    return <Route {...props} />;
  }
  return <Redirect to="/login" />;
};

PrivateRoute.propTypes = {
  permission: PropTypes.string,
};
PrivateRoute.defaultProps = {
  permission: '',
};

export default PrivateRoute;
